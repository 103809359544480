<template>
  <div id="body" style="background-color: #FFFFFF;">
    <div class="header">
      <p>添加学校</p>
    </div>
    <div class="content">
      <el-form ref="form" :model="form" label-width="80px">

        <!-- <el-form-item label="昵称">
        <el-input
          v-model="form.guaName"
          style="width: 200px; float: left"
        ></el-input>
      </el-form-item> -->

        <el-form-item label="投放城市">
          <el-select v-model="form.province" placeholder="请选择省份" style="float: left;width: 48%;" @change="Province">
            <el-option v-for="item in provinceList" :key="item.ID" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
          <el-select v-model="form.city" placeholder="请选择城市" style="float: left;" @change="City">
            <el-option v-for="item in cityList" :key="item.ID" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=投放学校>
          <el-select v-model="form.school" placeholder="请选择学校" filterable
            style="width: -webkit-fill-available; float: left" @change="School">
            <el-option v-for="item in SchoolList" :key="item.ID" :label="item.SchoolName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="投放理由">
          <el-input type="textarea" v-model="form.reason" :autosize="{ minRows: 5, maxRows: 7 }"
            style="width: 430px; float: left" placeholder="请说明您的投放理由"></el-input>
        </el-form-item>

        <el-form-item label="联系电话">
          <el-input v-model="form.tel" style="width: 200px; float: left"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="float: left">申请</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import {
    proviceCitySchool,
    schoolBindApply
  } from '@/api/agentApi.js'
  export default {
    data() {
      return {
        form: {
          school: '', // 投放学校
          reason: '', // 投放理由
          province: '', // 省
          city: '', // 市
          area: '', // 市
          tel: '' // 电话
        },
        regex: /^1[3456789]\d{9}$/, // 手机号正则
        provinceList: [], // 省
        cityList: [], // 市
        areaList: [], // 市
        SchoolList: [], // 校
        SchoolID: '', // 学校ID
        CityListCopy: [], // 市副本
        SchoolListCopy: [] // 校副本
      }
    },
    mounted() {
      this.proviceCitySchool() // 获取省市校信息
    },
    methods: {
      proviceCitySchool() { // 省市校
        proviceCitySchool().then(res => {
          // console.log(res);
          this.provinceList = res.data.province // 省
          this.cityList = res.data.city // 市
          this.CityListCopy = res.data.city // 市副本
          this.SchoolList = res.data.school // 校
          this.SchoolListCopy = res.data.school // 校副本
        }).catch(err => {
          console.log(err)
        })
      },
      Province(val) { // 省份选择
        // console.log(val);
        this.form.school = ''
        this.form.city = ''
        this.cityList = this.CityListCopy
        this.cityList = this.cityList.filter(item => item.ProvinceId == val)
      },
      City(val) { // 城市选择
        // console.log(val);
        this.form.school = ''
        this.SchoolList = this.SchoolListCopy
        this.SchoolList = this.SchoolList.filter(item => item.City == val)
      },
      School(val) { // 学校选择
        // console.log(val);
        this.SchoolID = val
      },
      onSubmit() { // 点击申请
        const data = {
          schoolId: this.SchoolID, // 学校ID
          remarks: this.form.reason, // 备注
          tel: this.form.tel // 电话
        }

        if (!this.regex.test(this.form.tel)) {
          this.$message('手机号输入有误')
          return false;
        }

        schoolBindApply(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '申请成功',
              type: 'success'
            })
            this.form.school = '' // 清空
            this.form.reason = '' // 清空
            this.form.province = '' // 清空
            this.form.city = '' // 清空
            this.form.tel = '' // 清空

          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    background-color: #F0F2F5;
  }

  .header {
    width: 100%;
    display: flex;
    margin-top: -22px;
    background: white;

    p {
      font-size: 20px;
      font-family: PingFangSC-Medium;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
      margin-left: 47px;
    }
  }

  .content {
    display: flex;
    width: 500px;
    margin: 0 auto;
    margin-top: 23px;
  }
</style>
